<template lang="">
    <Edit />
</template>
<script>
export default {
    components:{
        Edit : () => import("@/components/transfer/TransferEdit")
    }
}
</script>
<style lang="">
    
</style>